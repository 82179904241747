<template>
    <div class="editor">
        <Toolbar class="toolBar" :editor="editorRef" :defaultConfig="toolbarConfig" :mode="mode" />
        <Editor :style="{'min-height':height,'height':'auto'}" v-model="valueHtml" :defaultConfig="editorConfig" :mode="mode" @onCreated="handleCreated" @onChange="handleChange" />
    </div>
</template>

<script>
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import store from '../store';
import { onBeforeUnmount, ref, shallowRef, onMounted, getCurrentInstance } from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

export default {
    components: {
        Editor,
        Toolbar
    },
    props: {
        value: {
            type: String
        },
        height:{
            type: String,
            default:'300px'
        }
    },
    setup(props, ctx) {
        const { proxy } = getCurrentInstance();
        // 编辑器实例，必须用 shallowRef
        const editorRef = shallowRef();

        // 内容 HTML
        const valueHtml = ref('');
        //编辑器配置
        const editorConfig = {
            placeholder: '请输入内容...',
            MENU_CONF: {},
            scroll: false
        };
        //工具栏配置
        const toolbarConfig = {
            excludeKeys: [
                'fullScreen',
                'uploadVideo',
                'editImage'
            ]
        }

        onMounted(() => {
            setTimeout(() => {
                valueHtml.value = props.value;
            }, 1500)
        })

        const handleChange = (editor) => {
            ctx.emit('handEditor',editor.getHtml())
        }


        const handleCreated = (editor) => {
            editorRef.value = editor // editor 实例
            //获取menu
            editorConfig.MENU_CONF = editor.getConfig().MENU_CONF;
            //上传图片配置
            uploadImage();
            //编辑图片配置
            // EditUploadImage();
        }

        // const EditUploadImage = () => {
        //     editorConfig.MENU_CONF['editImage'] = {
        //         onUpdatedImage(imageNode) {
        //             if (imageNode == null) return
        //             const { src, alt, url } = imageNode
        //         },
        //         checkImage: customCheckImageFn, // 也支持 async 函数
        //         parseImageSrc: customParseImageSrc,
        //     }
        // }
        // const customCheckImageFn = (src, alt, href) => {
        //     if (!href) return
        //     if (href.indexOf('http') !== 0) return '图片网址必须以 http/https 开头'
        //     return true
        // }

        // const customParseImageSrc = (href) => {
        //     if (href.indexOf('http') !== 0) return `http://${href}`
        //     return href
        // }

        const uploadImage = () => {
            editorConfig.MENU_CONF['uploadImage'] = {
                server: proxy.domain + '/upload/upload', // 上传图片地址
                allowedFileTypes: ['image/*'],
                meta: {
                    file_type: 'article'
                },
                fieldName: 'file_name',
                headers: {
                    'X-CSRF-TOKEN': store.state._token
                },
                maxFileSize: 10 * 1024 * 1024, // 10M

                onBeforeUpload(files) {
                    console.log('onBeforeUpload', files)
                    return files // 返回哪些文件可以上传
                },
                onProgress(progress) {
                    console.log('onProgress', progress)
                },
                onSuccess(file, res) {
                    console.log(res);
                    console.log('onSuccess', file, res)
                },
                onFailed(file, res) {
                    console.log('onFailed', file, res)
                },
                onError(file, err, res) {
                    console.error('onError', file, err, res)
                },
                // // 用户自定义插入图片
                customInsert(res, insertFn) {
                    const url = proxy.domain + res.data.path || '';
                    insertFn(url)
                },
            }
        }

        // 组件销毁时，也及时销毁编辑器
        onBeforeUnmount(() => {
            const editor = editorRef.value
            if (editor == null) return
            editor.destroy()
        })

        return {
            editorRef,
            toolbar,
            valueHtml,
            mode: 'default', // 或 'simple'
            toolbarConfig,
            editorConfig,
            handleCreated,
            handleChange,
            uploadImage,
            // EditUploadImage,
            // customParseImageSrc,
            // customCheckImageFn,
            // editChange
        };
    }
}
</script>    
<style lang="less" scoped>
:deep(.w-e-image-container) {
    margin: 0 !important;
}
.editor {
    width: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 0;
    border: 1px solid #efefef;
    .toolBar {
        border-bottom: 1px solid #efefef;
    }
}
</style>